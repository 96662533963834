import { getProductPage } from "@10x/cms/resolvers/get-product-page";
import { getPaths } from "@10x/cms/utils/get-paths";
import PageLayout from "@10x/ui/src/PageLayout";
import type { GetStaticPaths, GetStaticProps, NextPage } from "next";

interface Props {
  data: any;
}

const Product: NextPage<Props> = ({ data }) => <PageLayout data={data} />;

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const paths = getPaths("product-pages", locales!);

  return {
    fallback: false,
    paths,
  };
};

export const getStaticProps: GetStaticProps = async ({ locale, params }) => {
  const data = await getProductPage(`${params!.slug}`, locale);

  return {
    props: {
      data: data,
    },
  };
};

export default Product;
